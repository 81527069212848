.tbody-scroll {
    max-height: 25rem; /* This sets the max height for the scrollable area */
    overflow-y: auto;
  }
  
  /* Ensures that the columns have equal width */
  
  /* Fixes the header at the top */
  thead th {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white; /* or the color of your choice */
  }
  
  /* Add any additional styling you need for the table, cells, and other elements */
  
  .download-button {
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  