/* Base styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  width: 90%; /* Adjust as needed */
  max-width: 500px; /* Maximum width */
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.result-popup {
  font-family: 'Arial', sans-serif; /* Replace with your font */
  text-align: center;
}

.result-popup h2 {
  color: #21808d; /* Replace with the exact color code */
  margin-bottom: 1rem;
}

.result-popup img {
  height: auto;
  /* max-width: 100px; Adjust as needed */
  margin: 0 auto 1rem;
}

.result-details {
  border-top: 1px solid #e0e0e0; /* Light grey border */
  padding-top: 1rem;
}

.grade {
  display: flex;
  flex-direction: column; /* Stack percentage and label on small screens */
  align-items: center;
  margin-bottom: 1rem;
}

.grade .percentage {
  display: inline-block;
  background-color: #f2f2f2; /* Light grey background */
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1.5rem;
  margin-bottom: 0.5rem; /* Add space between percentage and label */
}

/* Specific styling for correct and wrong answers */
.correct-answer,
.wrong-answer {
  margin: 0.5rem 0; /* Add space between answer lines */
}

.feedback-button {
  background-color: #21808d; /* Replace with the exact color code */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  width: 80%; /* Button width relative to its container */
  max-width: 200px; /* Maximum button width */
  margin-left: auto;
  margin-right: auto; /* Center button */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5rem;
}

/* Media Queries */
@media (max-width: 600px) {
  .modal-content {
    width: 95%; /* Increase width on very small screens */
    padding: 15px; /* Reduce padding */
  }

  .grade .percentage {
    font-size: 1.2rem; /* Reduce font size for small screens */
  }

  .feedback-button {
    padding: 8px 16px; /* Slightly reduce padding */
  }
}

@media (max-width: 400px) {
  .grade .percentage {
    font-size: 1rem; /* Even smaller font size for very small screens */
  }
}
