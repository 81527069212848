#layout {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.501);
}

#layout .navbar {
  width: 100%;
  padding: 0px !important;
}

#layout main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 73px;
  height: calc(100vh - 73px);
  overflow-y: auto;
}
