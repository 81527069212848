.modal-overlay-statutes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other content */
}

.modal-content-statutes {
  background: white;
  padding: 20px 20px 70px 20px;
  border-radius: 5px;
  max-width: 70%;
  width: 90%;
  height: 80vh;
  /* or another value that fits well in your modal */
  overflow-y: auto;
  /* Enable vertical scrolling */
  z-index: 1001;
}

.download-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #20808D;
  /* Match your modal's theme */
  color: white
}