.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .download-icon {
    background-color: white;
    text-align: center;
    padding: 10px;
   
  }
  .main-statute {
    overflow-y: auto; /* Enable vertical scrolling */
    height: auto;     /* Allow content to grow */
    position: relative; /* Ensure layout context is preserved */
  }
  