.sideChat {
  width: 20%;
  margin-top: 0px;
  border-radius: 0px 30px 0px 0px;
  background: #000;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  position: relative; /* Allow fixed positioning inside */
}

#chatHistory {
  flex: 1; /* Let the chat history take remaining space */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden !important;
  position: relative;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0px;

  /* Add more styling as needed */
}

.sideChat .first-box {
  margin-bottom: 55px;
  flex: 1;
  width: 100%;
  overflow-y: auto; /* Allow scroll for the chat history */
}

.first-box::-webkit-scrollbar {
  display: none;
}

.sideChat .second-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
}
.my-btn button:hover {
  background-color: #1a6e75; /* Lighter shade of button on hover */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

/* Press effect for button (shrink effect) */
.my-btn button:active {
  transform: scale(0.98); /* Slightly shrink on click */
  transition: transform 0.1s ease; /* Instant press effect */
}
.chat-heading {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  background: rgba(62, 62, 62, 0.29);
  border-bottom: 1px solid rgba(62, 62, 62, 0.29);
  position: absolute;
  top: 0;
  z-index: 1;
}

#chatHistory .no-dropdown-toggle {
  border: none;
  outline: none;
}

#chatHistory .no-dropdown-toggle::after {
  display: none;
}

#chatHistory .dropdown-menu2 {
  width: 120px !important;
  border: 2px solid white;
  margin-top: 5px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: absolute;
  right: 10px;
  top: 40px;
  cursor: pointer;
}

#chatHistory .dropdown-toggle {
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 0px;
  color: #fff;
}
.dropdown-toggle::after {
  display: none;
}

@media screen and (max-width: 768px) {
  .sideChat {
    width: 100% !important;
    border-radius: 0;
    height: 76.6vh !important;
  }
  #chatHistory {
    height: 68vh !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .sideChat {
    width: 36%;
  }
}
