.judgement-message {
  margin: 10px 45px;
  padding: 10px;
  border-left: 3px solid #4CAF50;
  background-color: #f2f2f2;
  border-radius: 5px;
}

.judgement-message p {
  margin: 0;
  color: #333;
  font-size: 0.9rem;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.react-pdf__Page{
  padding: 10px !important;
  background-color: transparent !important;
}

#hide-conversation-view {
  display: none;
}
#conversation-view.statute-view {
  display: block;
  height: fit-content;
  max-height: fit-content;
}
#conversation-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 85px;
}

#conversation-view .top-bar {
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  height: 10%;
  border-bottom: 1px solid rgb(218, 218, 218);
}

#conversation-view .top-bar .bar-btn {
  display: flex;
  align-items: center;
  opacity: 0.7;
  cursor: pointer;
}

#conversation-view .top-bar .bar-btn span {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  color: #a3a3a3;
}

#conversation-view .top-bar .bar-btn span:hover {
  color: black;
}

#conversation-view .conversation-box {
  width: 100%;
  height: 78%;
  overflow-y: scroll;
  padding: 0px 80px 0px 0px;
  margin: 10px 0px;
}
#conversation-view .conversation-box::-webkit-scrollbar {
  width: 7px;
}

#conversation-view .conversation-box::-webkit-scrollbar-thumb {
  background: #bfbbbb;
  border-radius: 31px;
}

#conversation-view .conversation-box .sender-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
}

#conversation-view .conversation-box .sender-message {
  width: auto;
  /* min-width: 30%; */
  max-width: 100%;
  min-height: 60px;
  background: rgba(232, 232, 232, 0.7);
  border-radius: 20px;
  border: 0.821px solid #fff;
  margin-top: -10px;
  margin-left: 20px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: rgba(90, 90, 90, 1);
  padding: 17px 20px 7px 20px;
}

#conversation-view .conversation-box .receiver-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
}
#conversation-view .conversation-box .receiver-judgement-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  border-radius: 34px;
  border: 1px solid #cfcfcf;
  background: rgba(232, 232, 232, 0.7);
}

#conversation-view .conversation-box .receiver-div .img-box {
  background: rgba(19, 52, 59, 1);
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#conversation-view .conversation-box .receiver-div .img-box img {
  width: 35px;
  height: 35px;
}

#conversation-view .conversation-box .receiver-message {
  width: 100%;
  max-width: 100%;
  min-height: 60px;
  background: rgba(232, 232, 232, 0.7);
  border-radius: 34px;
  border: 1px solid #cfcfcf;
  margin-left: 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: rgba(90, 90, 90, 1);
  padding: 0px 0px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
#conversation-view .conversation-box .general_receiver-message {
  width: 100%;
  max-width: 100%;
  min-height: 60px;
  margin-left: 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: black;
  padding: 3px 10px 2px 16px;
  /* padding: 6px 30px 5px 16px; */
  gap: 0px;
}

#conversation-view .conversation-box .error-box {
  width: 100%;
  max-width: 100%;
  min-height: 60px;
  background: rgba(255, 0, 0, 0.046);
  border-radius: 20px;
  border: 1px solid rgba(255, 0, 0, 0.347);
  margin-left: 30px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: rgb(255, 0, 0);
  padding: 20px 20px;
}

#conversation-view .input-box {
  width: 92%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
}

#conversation-view .input-box p {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  margin-top: 5px;
  /* color: #13343b; */
  opacity: 0.7;
}

#conversation-view .custom-loader {
  margin-left: 30px;
  width: 50px;
  height: 24px;
  background: radial-gradient(
        circle closest-side,
        rgba(19, 52, 59, 1) 90%,
        #0000
      )
      0% 50%,
    radial-gradient(circle closest-side, rgba(19, 52, 59, 1) 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, rgba(19, 52, 59, 1) 90%, #0000) 100%
      50%;
  background-size: calc(100% / 3) 12px;
  background-repeat: no-repeat;
  animation: d3 1s infinite linear;
}

.empty-receiver-message {
  width: 100%;
  max-width: 100%;
  min-height: 60px;
  border-radius: 34px;
  margin-left: 30px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: rgba(90, 90, 90, 1);
  padding: 0px 0px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

@keyframes d3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

@media screen and (max-width: 1500px) {
  #conversation-view {
    height: 100%;
    padding-left: 75px;
  }


  #conversation-view .conversation-box {
    height: 81%;
  }

  #conversation-view .top-bar {
    height: 13%;
  }
}
@media screen and (max-width: 769px) {
#conversation-view {
    padding-left: 18px;
    height: 76.6vh; /* Sets height to 86% of the viewport height */
    position: relative; /* Adjust position as needed */
  }

  #conversation-view .input-box {
    width: 100%;
  padding-bottom: 0;
  }
  #conversation-view .input-box p {
    font-size: 8px;
    background-color: #bfbbbb;
    text-align: center;
    margin-left: -20px;
  }

  #conversation-view .conversation-box {
    padding: 0px 15px 0px 0px;

  }
  #conversation-view .conversation-box .receiver-div .img-box {
    width: 30px;
    height: 30px;
    
  }
}
@media screen and (max-width: 724px) {


  #conversation-view .conversation-box .general_receiver-message {
    width: 100%;
    max-width: 100%;
    min-height: 60px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    color: black;
    padding: 6px 15px 10px 0px;
    gap: 0px;
  }

}

#conversation-view .mcqs-tab {
  padding-left: 0 !important; /* Overrides general padding */
}
@media screen and (max-width: 1500px) {
  #conversation-view .mcqs-tab {
    padding-left: 0 !important; /* Overrides general padding */
}
}