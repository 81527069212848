/* Utility classes for common styles */
* {
  font-family: Poppins; 
}
.text-white {
   color: white;
}

.text-grey {
   color: grey;
}

.text-black {
   color: black;
}

.text-center {
   text-align: center;
}

.bg-cover {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}

.btn-rounded {
   border-radius: 22px;
   padding: 4px 36px;
}

/* Margin and padding utility classes */
.mt-12 {
   margin-top: 12px;
}

.mt-20 {
   margin-top: 20px;
}

.mt-30 {
   margin-top: 30px;
}

.mt-40 {
   margin-top: 40px;
}

.mt-80 {
   margin-top: 80px;
}

.mb-30 {
   margin-bottom: 30px;
}

.mb-40 {
   margin-bottom: 40px;
}

.mx-auto {
   margin: 0 auto;
}

.ml-20 {
   margin-left: 20px;
}

/* Font size utility classes */
.fs-large {
   font-size: 50px;
}

.fs-medium {
   font-size: 22px;
}

.fs-small {
   font-size: 16px;
}

.fs-smaller {
   font-size: 14px;
}

/* Header styles */
.header {
   background-image: url('../assets/bgHero.jpg');
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}

.header .nav-link {
   color: white;
}

.header .nav-link:active {
   color: grey;
}

.header .main-btn .btn {
   margin-top: 12px;
   margin-right: -5px;
   border-radius: 22px;
   padding: 4px 36px;
}

.header .text-end .btn:hover {
   background-color: white;
   color: black;
}

/* Section common styles */
.section {
   margin-top: 80px;
}

.section h1 {
   font-size: 36px;
   font-weight: 600;
   text-transform: capitalize;
}

.section p {
   line-height: 27px;
   font-size: 18px;
}

/* Intro section */
.intro-section {
   color: white;
}

.intro-section .heading {
   margin-top: 20px;
   margin-left: 20px;
}


.intro-section p {
   margin-top: 20px;
}

.intro-section .intro-btn {
   margin-left: 20px;
   margin-bottom: 30px;
   margin-top: 30px;
   font-weight: 500;
   color: black;
   border: 2px solid white;
   background-color: white;
   font-size: 22px;
   padding: 4px 40px;
   border-radius: 25px;
   text-decoration: none;
}

/* About section */
.about-section {
   color: white;
}

.about-section .about {
   text-transform: capitalize;
   text-align: center;
}

.about-section .about p {
   font-size: 16px;
}

/* Testimonials section */
.testimonials {
   color: white;
}

.testimonials .content-wrapper {
   padding: 60px 20px;
   box-shadow: 9px 3px 27px -15px;
   margin-bottom: 20px;
   margin-top: 20px;
}

.testimonials .text {
   text-align: center;
   margin-bottom: 40px;
}

.testimonials .fig-heading h5 {
   font-size: 20px;
   margin-bottom: 0px;
}

.testimonials .fig-heading p {
   font-size: 11px;
   padding-bottom: 25px;
}

.testimonials .details p {
   background-color: #464646;
   font-size: 18px;
   line-height: 32px;
}

/* Services section */
.services-section {
   color: white;
   padding-bottom: 40px;
}

.services-section .service-box {
   background: white;
   margin: 30px 10px;
   padding: 30px 40px 10px;
   border-bottom: 1px solid #e7e4e4;
   box-shadow: 2px 0 22px -14px rgb(33, 75, 79);
   color: black;
}

.services-section .service-box img {
   width: 70px;
   height: 70px;
}

.services-section .service-box figcaption {
   min-height: 120px;
}

.services-section .service-box h5 {
   margin-top: 12px;
   font-size: 24px;
   font-weight: 600;
   text-transform: capitalize;
}

@media only screen and (max-width: 768px) {
   .services-section .service-box {
      padding: 20px;
      margin: 15px 0px;
   }

   .services-section .service-box img {
      height: 70px;
   }

   .services-section .service-box figcaption {
      min-height: 120px;
   }
}

/* User section */
.user-section {
   color: white;
}

.user-section .text {
   text-align: center;
}

.user-section .text h1 {
   font-size: 36px;
   font-weight: 600;
}

.user-section .text p {
   font-size: 16px;
   font-weight: 400;
}

.user-section .my-btn {
   text-align: center;
}

.user-section .user-btn {
   text-decoration: none;
   margin-left: 20px;
   margin-bottom: 30px;
   margin-top: 30px;
   font-weight: 500;
   color: white;
   border: 2px solid white;
   background-color: #13343B;
   font-size: 22px;
   padding: 4px 40px;
   border-radius: 25px;
}

/* Contact section */
.contact-us {
   margin-top: 80px;
   margin-bottom: 40px;
}

.contact-us .marketing {
   text-align: center;
   align-items: center;
}

.contact-us .my-img {
   display: flex;
   justify-content: center;
}
               
/* new pricing */
.toggle {
  width: 70px; 
  height: 25px; 
  background-color: #ccc; 
  border-radius: 25px; 
  position: relative;
  transition: background-color 0.3s;
}

.toggle.active {
  background-color: #0e877d; 
}

.toggle-slider {
  height: 35px; 
  width: 35px;
  background-color: white; 
  border-radius: 50%; 
  position: absolute;
  top: -4px;
  left:0px;
  transition: transform 0.3s;
}

.toggle.active .toggle-slider {
  transform: translateX(40px); 
}
.off-para{
  font-family: "Fasthand", cursive;
  font-weight: 400;
  font-style: normal;
}


.info-section {
   padding: 30px 0px;
   margin-top: 80px;
   background-color: #21808D;
}

.info-section a {
   font-family: "Montserrat";
   font-size: 16px;
   font-weight: 500;
   margin-left: 12px;
   margin-top: 4px;
   text-decoration: none;
   color: white;
}

.responsive-image {
   max-width: 100%;
   height: auto;
   display: block;
   margin-left: auto;
   margin-right: auto;
}

.custom-center {
   display: flex;
   justify-content: center;
   align-items: center;
}

.custom-column {
   display: flex;
   justify-content: center;
   align-items: center;
}

                    /* Testimonialsss */


.marquee-container {
   margin-top: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 100%;
  overflow: hidden;
  border-radius: 1rem;
  background-color: var(--background-color);
  box-shadow: var(--shadow-xl);
}

.review-card {
  height: 30vh;
  margin: 10px;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30rem;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #0D9488;
  background-color: #212121;
  padding: 1rem;
  transition: background-color 0.3s;
}

.review-card:hover {
  background-color: rgba(15, 15, 15, 0.05);
}

.review-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.review-user-info {
  display: flex;
  flex-direction: column;
}

.review-name {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.review-username {
  font-size: 12px;
  /* font-weight: 500; */
  color: rgba(255, 255, 255, 0.6);
}

.review-body {
  margin-top: 0.5rem;
  font-size: 15px;
  color: #fff;
}

@media (max-width: 1024px) {
  .review-card {
    width: 22rem;
  }
}

@media (max-width: 768px) {
  .marquee-container {
    height: auto;
    padding: 2rem 0;
  }

  .review-card {
    height: auto;
    margin: 10px;
    width: 90%;
    border: 1px solid #0D9488;
    background-color: #212121;
    padding: 1rem;
    transition: background-color 0.3s;
  }

  .review-avatar {
    width: 24px;
    height: 24px;
  }

  .review-name {
    font-size: 16px;
  }

  .review-username {
    font-size: 10px;
  }

  .review-body {
    font-size: 8px;
    width: 180px;
  }
}