.app {
    display: flex;
    height: 100vh; /* Adjust height as necessary */
  }
  
  .sidebar {
    top: 70px;
    width: 250px; /* Adjust width as necessary */
    height: 100%;
    position: fixed;
    overflow-y: auto; /* for scrollable sidebar */
  }
  
  .quiz-data {
    margin-left: 250px; /* Equal to sidebar width */
    flex-grow: 1;
    overflow-y: auto; /* for scrollable content */
    height: 100%;
  }
  /* Light mode styles */
.quiz-button-container-light {
  background-color: #fff; /* Replace with your light mode background color */
}

/* Dark mode styles */
.quiz-button-container-dark {
  background-color: #333; /* Replace with your dark mode background color */
}
  
  @media screen and (max-width: 768px) {

    .quiz-data {
      margin-left: 0; 
    }
    .sidebar{
      display:none;
    }
  }

  .quiz-divider{
    border-bottom: 1px solid white;

  }