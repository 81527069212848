* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

input:-webkit-autofill {
  background: none !important;
  -webkit-text-fill-color: initial !important;
  transition: background-color 5000s ease-in-out 0s;
}

.custom-confirm-button {
  width: 140px !important;
  height: 45px !important;
  background-color: #20808d !important;
  border: none !important;
  outline: none !important;
  border-radius: 150px !important;
  color: white !important;
  box-shadow: none !important;
}

.dropdown {
  margin-left: -9px !important;
}

.plan_payment::-webkit-scrollbar {
  display: none !important;
}

.button_hover:hover {
  background-position: 100% 0;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.blur_bg {
  backdrop-filter: blur(4px) saturate(122%);
  -webkit-backdrop-filter: blur(4px) saturate(122%);
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  width: 100%;
  /* height: 90vh; */
  z-index: 99999;
}

.line {
  width: 112px;
  height: 1px;
  border-top: 1px solid black;
  position: absolute;
}

/* CSS */

/* //Blogs css imports */