#suggestions {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#suggestions .box {
  width: 49%;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 13px;
  border: 2px solid #f3f3ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  cursor: pointer;
}

#suggestions .box:hover {
  border-radius: 13px;
  border: 2px solid #fff;
  background: #f3f3ee;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

#suggestions .box h5 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  color: rgba(19, 52, 59, 1);
}

#suggestions .box p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(19, 52, 59, 1);
  opacity: 0.4;
}

#suggestions .box .icon {
  display: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

#suggestions .box:hover .icon {
  display: block;
}

#input-container {
  width: 75%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0px 2px 8px 0px #0000001a;
  border: 2px solid #f3f3ee;
  padding: 0px 30px 0px 50px;
  /* min-height: 60px; */
  overflow-y: auto;
}

#input-container textarea {
  margin-top: 7px;
  width: 95%;
  padding: 10px 0px;
  max-height: 80px; 
  border: none;
  outline: none;
  resize: none;
  overflow-y: auto;
  outline: none;
}
#input-container textarea::-webkit-scrollbar {
  width: 7px;
}
.custom-textarea:focus {
  outline: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.custom-textarea::placeholder {
  font-size: 0.875rem; /* This sets the font size to small (about 14px if the base font size is 16px) */
  color: gray; /* Optional: changes the color of the placeholder text */
}


#input-container textarea::-webkit-scrollbar-thumb {
  background: #bfbbbb;
  border-radius: 31px;
}
#input-container button {
  border: none;
  outline: none;
  background: none;
}

#input-container img {
  width: 25px;
  height: 25px;
}

.border_dotted {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 10px;
  line-height: 100px;
  background: linear-gradient(to right, #828282 50%, transparent 0%) top
    repeat-x;
  background-size: 20px 1px, 1px 20px;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #6d6d6d;
  border-radius: 70px;
}
/*Firefox */
.custom-scrollbar {
  scrollbar-color: #6d6d6d transparent;
}
/*Edge */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #6d6d6d transparent;
}
.login_box::-webkit-scrollbar {
  height: 7px !important;
  width: 7px;
}
.login_box::-webkit-scrollbar-track {
  border-radius: 5px;
}
.login_box::-webkit-scrollbar-track:active {
  background-color: #c2c2c2;
}
.login_box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #bfbbbb;
}

@media screen and (max-width: 1500px) {
  #suggestions .box {
    height: 90px;
  }

  #suggestions .box h5 {
    font-size: 18px;
  }

  #suggestions .box p {
    font-size: 14px;
  }

  #input-container {
    padding: 0px 30px 0px 30px;
  }
}
@media screen and (max-width: 789px) {

  #input-container {
    /* margin-top: 30px; */
    padding: 5px 10px 5px 10px;
  }
  #input-container textarea {
    margin-top: 0px;
    width: 95%;
    padding: 10px 0px;
    max-height: 80px; 
    border: none;
    outline: none;
    resize: none;
    overflow-y: auto;
    outline: none;
  }
}





.custom-background {
  background: radial-gradient(
      circle at top right,
      #1a3432, /* green color */
      rgba(0, 0, 0, 30%) 30% /* fades to semi-transparent black */
    ),
    radial-gradient(
      circle at bottom left,
      #1a3432, /* green color */
      rgba(0, 0, 0, 30%) 30% /* fades to semi-transparent black */
    ),
    #000; /* solid black base color to ensure coverage */
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}