
.selected-tab-light {
  background-color: white;
  /* Background color for selected tab in light mode */
  color: #13343B;
  /* Text color for selected tab in light mode */
}

/* Light mode styles for non-selected tabs */
.non-selected-tab-light {
  background-color: "#c4c4c4";
  /* Background color for non-selected tabs in light mode */
}

/* Dark mode styles for selected tab */
.selected-tab-dark {
  background-color: #232123;
  /* Background color for selected tab in dark mode */
}

/* Dark mode styles for non-selected tabs */
.non-selected-tab-dark {
  background-color: #353535;
  /* Background color for non-selected tabs in dark mode */
}

.top-Navbar {
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cee9ff;
  align-items: center;
  padding: 0px 15px;
  background: #f3f3ee;
  position: fixed;
  /* Fix the navbar position */
  top: 0;
  /* left: 0; */
  z-index: 1000;
  /* Make sure this z-index is higher than the sidebar's */
}

.top-Navbar .logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.top-Navbar .logo .icon {
  font-size: 50px;
  cursor: pointer;
}

.top-Navbar .logo img {
  width: 150px;
  height: 40px;
}

@media screen and (max-width: 1653px) {
  .top-Navbar .logo .icon {
    font-size: 50px;
  }

  .top-Navbar .logo img {
    width: 130px;
    height: 35px;
  }
}

@media screen and (max-width: 769px) {

  .top-Navbar {
    border-bottom: none;
    height: 52px;
  }
}
@media screen and (min-width: 1560px) {

  .top-Navbar {
    /* border-bottom: none; */
    height: 79px;
  }
}

@media screen and (min-width: 769px) {
  .navbar-in {
    display: none;
  }

  .top-Navbar .logo img {
    width: 130px;
    height: 35px;
  }

}