/* Pagination.css */
/* 
.custom-button,
.previous-button {
    padding: 10px 20px;
    margin-right: 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
  }
  
  .default-button,
  .previous-button  {
    background-color: #21808d;
    color: #fff;
  } */

.view-more-button,
.previous-button {
  background-color: #21808d;
  color: #fff;
  transform: scale(1);
}

.view-more-button:hover,
.previous-button:hover {
  background-color: #2ecc71;
  transform: scale(1.05);
}

.disabled-button {
  background-color: #dcdcdc;
  color: #888;
  cursor: not-allowed;
}

.pagination-ellipsis {
  display: inline-block;
  padding: 8px 12px;
  /* Additional styling for the ellipsis */
}

.page-button {
  /* General style for all page buttons */
  background-color: #fff;
  /* Default background */
  color: #333;
  /* Default text color */
  border: 1px solid #ddd;
  padding: 4px 12px;
  cursor: pointer;
  margin: 0 4px;
  /* Spacing between buttons */
  border-radius: 4px;
  /* Rounded corners */
  transition: background-color 0.3s;
  /* Smooth background transition */
}

.page-button:hover {
  background-color: #f5f5f5;
  /* Hover background color */
}

.page-button.active {
  background-color: #21808d;
  /* Active page background */
  color: white;
  /* Active page text color */
  cursor: default;
}

.page-button.disabled {
  background-color: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
}


.pagination-container {
  position: static !important;
  background-color: white;
  margin-top: 20px;
}