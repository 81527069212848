@tailwind base;
@tailwind components;
@tailwind utilities;

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
small {
  margin: 0;
  padding: 0;
}
@layer components {
  .writing-mode {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}
.highlightKeywords{
  background-color: yellow;
}