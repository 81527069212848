

.navbar-res .navbar {
  display: flex;
  position: fixed;
  justify-content: space-around;
  align-items: center;
  list-style-type: none;
  margin: 0;
  margin-top: 52px;
  padding-top: 12px;
  top: 0;
  left: 0;
  padding-left: 0;
  z-index: 1000;
}

.navbar-res .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  cursor: pointer;
  /* background-color: #F3F3EE; */
  transition: background-color 0.3s ease;
}


.navbar-res .tab.active {
  border-bottom: 1px solid rgba(37, 37, 213, 0.716);
  background-color: #fff;
}

.navbar-res .tab.active.dark-selected {
  border-bottom: 2px solid white;
  background-color: #1d1b1d;
  color: white;
}

.navbar-res .tab span {
  color: #13343B;
  opacity: 0.7;
}

.navbar-res span.dark-selected {
  color: white;
  opacity: 0.7;
}

.active-button {
  background-color: #20808D;
}

.navbar-res .navbar-actions {
  /* background-color: #d1d1c4; */
  border: 1px solid #E7E9E7;
  padding: 10px 0px;
  margin-bottom: 8px;
  margin-top: 14px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
}

.navbar-res .action-link {
  text-align: center;
  padding: 12px 6px;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.navbar-res .chat-history {
  margin-right: 10px;
}

.navbar-res .sidebar {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .navbar-res .navbar {
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  .navbar-res .tab,
  .action-link {
    font-size: 12px;
  }

  .navbar-res .navbar-actions {
    margin: 0px 57px;
    margin-top: 18px;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .navbar-res .navbar-wrapper {
    border: none;
    display: flex;
    justify-content: space-between;
  }

  .navbar-res .navbar-actions {
    flex-direction: row;
    align-items: center;
  }

  .navbar-res {
    display: none;

  }
}