.skeleton {
    background-color: #8c8f94 ;
    animation: pulse 1.5s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
  
  .skeleton-chat-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    height: 60px;
  }
  
  .skeleton-circle {
    width: 10px;
    height: 65px;
    border-radius: 0px 11px 11px 0px;
    margin-right: 10px;
  }
  
  .skeleton-line-1 {
    margin-top: 5px;
    width: 50%;
    height: 12px;
    margin-bottom: 5px;
  }
  .skeleton-line-2 {
    width: 88%;
    height: 12px;
    margin-bottom: 5px;
  }
  